import React, { useState, useEffect } from 'react';
import gsmkLogo from './assests/gsmk_logo.svg';
import vippsLogo from './assests/vipps_logo_negativ_rgb.png';
import izettleLogo from './assests/izettle-logo.png';
import './App.css';
import * as db from './db';

function App() {
  const [totalsAmount, setTotalsAmount] = useState(0);
  const [totalsCategoryAmount, setTotalsCategoryAmount] = useState<Record<string, number>>({});
  const [donationsAmount, setDonationsAmount] = useState(0);
  const [donationsCategoryAmount, setDonationsCategoryAmount] = useState<Record<string, number>>({});
  const [saturdayAmount, setSaturdayAmount] = useState(0);
  const [saturdayCategoryAmount, setSaturdayCategoryAmount] = useState<Record<string, number>>({});
  const [sundayAmount, setSundayAmount] = useState(0);
  const [sundayCategoryAmount, setSundayCategoryAmount] = useState<Record<string, number>>({});
  const [originFilter, setOriginFilter] = useState("");
  const [transactions, setTransactions] = useState<db.Transaction[]>([]);
  const saturdayDate = new Date("2021-10-16");
  const sundayDate = new Date("2021-10-17");
  const [toggleDetails, setToggleDetails] = useState(false);

  const handleFilterClick = (filterStr: string) => {
    setOriginFilter(currentFilter => {
      if(filterStr === currentFilter)
        return "";
      else
        return filterStr;
    })
  }

  const getOpacity = (elementName: string) => {
    if(originFilter === "" || elementName === originFilter)
      return 1;
    return 0.5
  }

  const updateNumbers = () => {
    let donations = 0;
    let saturdaySum = 0;
    let sundaySum = 0;
    let donationsCategorySum: Record<string, number> = {};
    let saturdayCategorySum: Record<string, number> = {};
    let sundayCategorySum: Record<string, number> = {};
    let totalsCategorySum: Record<string, number> = {};

    transactions.forEach(transaction => {
      if(originFilter === "" || originFilter === transaction.origin) {
        const date = transaction.timestamp.toDate();
        if(date.getFullYear() === saturdayDate.getFullYear()) {
          if(date < saturdayDate) {
            donations += transaction.amount;
            if(transaction.category) {
              donationsCategorySum[transaction.category] = (donationsCategorySum[transaction.category] || 0) + transaction.amount;
            }
            else { 
              donationsCategorySum["-"] = (donationsCategorySum["-"] || 0) + transaction.amount;
            }
          }
          if(date.toDateString() === saturdayDate.toDateString()) {
            saturdaySum += transaction.amount;
            if(transaction.category) {
              saturdayCategorySum[transaction.category] = (saturdayCategorySum[transaction.category] || 0) + transaction.amount;
            }
            else { 
              saturdayCategorySum["-"] = (saturdayCategorySum["-"] || 0) + transaction.amount;
            }
          }
          else if(date.toDateString() === sundayDate.toDateString()) {
            sundaySum += transaction.amount;
            if(transaction.category) {
              sundayCategorySum[transaction.category] = (sundayCategorySum[transaction.category] || 0) + transaction.amount;
            }
            else { 
              sundayCategorySum["-"] = (sundayCategorySum["-"] || 0) + transaction.amount;
            }
          }
          if(transaction.category) {
            totalsCategorySum[transaction.category] = (totalsCategorySum[transaction.category] || 0) + transaction.amount;
          }
          else { 
            totalsCategorySum["-"] = (totalsCategorySum["-"] || 0) + transaction.amount;
          }
        }
      }
    })
    
    saturdaySum = saturdaySum + donations; // for 2021, move donations to saturday
    donations = 0;
    //saturdayCategorySum = {...saturdayCategorySum, ...donationsCategorySum};
    saturdayCategorySum = [saturdayCategorySum, donationsCategorySum].reduce((basket, fruit) => {
      for (const [fruitName, fruitCount] of Object.entries(fruit)) {
          if (!basket[fruitName]) {
              basket[fruitName] = 0;
          }
  
          basket[fruitName] += fruitCount;
      }
  
      return basket;
    }, {});
    donationsCategorySum = {};
    
    setTotalsAmount(saturdaySum + sundaySum + donations);
    setTotalsCategoryAmount(totalsCategorySum);
    setDonationsAmount(donations);
    setDonationsCategoryAmount(donationsCategorySum);
    setSaturdayAmount(saturdaySum);
    setSaturdayCategoryAmount(saturdayCategorySum);
    setSundayAmount(sundaySum);
    setSundayCategoryAmount(sundayCategorySum);
  }

  useEffect(
    () => {
      updateNumbers();
    },
    [transactions, originFilter]
  );

  useEffect(
    () => {
      const unsubscribe = db.getTransationsListener(
        (snapshot) => {
          const transactions: db.Transaction[] = [];
          snapshot.forEach(doc => {
            const transaction = doc.data() as db.Transaction;
            transactions.push(transaction);
          });
          setTransactions(transactions);
        }, err => { console.error(err) }
      )
      // returning the unsubscribe function will ensure that
      // we unsubscribe from document changes when our id
      // changes to a different value.
      return () => unsubscribe()
    },
    []
  )

  const currencyFormat = (num: number) => {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
  }

  const categoryText = (key: string) => {
    if(key === '-')
      return "Annet";
    return key;
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src={gsmkLogo} className="gsmk-logo" alt="gsmk logo" />
        <div className="logos">
          <div onClick={() => handleFilterClick('vipps')}>
            <img src={vippsLogo} className="vipps-logo" alt="Vipps logo" style={{opacity: getOpacity('vipps')}} />
          </div>
          <span>+</span>
          <div onClick={() => handleFilterClick('izettle')}>
            <img src={izettleLogo} className="izettle-logo" alt="iZettle logo" style={{opacity: getOpacity('izettle')}} />
          </div>
        </div>
        <div className="card" onClick={() => setToggleDetails(!toggleDetails)}>
          <div className="day">Donasjoner:</div>
          {toggleDetails && Object.keys(donationsCategoryAmount).length > 0 ?
            Object.keys(donationsCategoryAmount).map(key =>
              <div key={key} className="details-amount">{categoryText(key)}: {currencyFormat(donationsCategoryAmount[key])},-</div>
            )
          :
            <div className="day-amount">{currencyFormat(donationsAmount)},-</div>
          }
        </div>
        <div className="App-days">
          <div className="card" onClick={() => setToggleDetails(!toggleDetails)}>
            <div className="day">Lørdag:</div>
            {toggleDetails && Object.keys(saturdayCategoryAmount).length > 0 ?
              Object.keys(saturdayCategoryAmount).map(key =>
                <div key={key} className="details-amount">{categoryText(key)}: {currencyFormat(saturdayCategoryAmount[key])},-</div>
              )
              :
              <div className="day-amount">{currencyFormat(saturdayAmount)},-</div>
            }
          </div>
          <div className="card" onClick={() => setToggleDetails(!toggleDetails)}>
            <div className="day">Søndag:</div>
            {toggleDetails && Object.keys(sundayCategoryAmount).length > 0 ?
              Object.keys(sundayCategoryAmount).map(key =>
                <div key={key} className="details-amount">{categoryText(key)}: {currencyFormat(sundayCategoryAmount[key])},-</div>
              )
              :
              <div className="day-amount">{currencyFormat(sundayAmount)},-</div>
            }
          </div>
        </div>
        <div className="card" onClick={() => setToggleDetails(!toggleDetails)}>
          <div>
            <div className="day">Totalt:</div>
            {toggleDetails && Object.keys(totalsCategoryAmount).length > 0 ?
              Object.keys(totalsCategoryAmount).map(key =>
                <div key={key} className="details-amount">{categoryText(key)}: {currencyFormat(totalsCategoryAmount[key])},-</div>
              )
              :
              <div className="day-amount">{currencyFormat(totalsAmount)},-</div>
            }
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
