import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/database";
import config from "./firebase-config";

firebase.initializeApp(config);

export const db = firebase.firestore();

export interface Transaction {
  timestamp: firebase.firestore.Timestamp;
  amount: number;
  origin: string;
  category?: string;
}

export const getTransationsListener = (
  onNext: (snapshot: firebase.firestore.QuerySnapshot) => void,
  onError?: (error: Error) => void
) => {
  return db.collection("transactions").onSnapshot(onNext, onError);
};

/*

export interface OnlineStatus {
  online: boolean;
  last_changed: Date;
}

export interface Chat {
  job_id: string;
  workflow_id: string;
  messages?: number;
}
db.enablePersistence().catch(function (err) {
  console.error(err);
});

export async function getUserData(userId: string) {
  const userDataPromise = await db.collection("users").doc(userId).get();
  return userDataPromise.data() as UserType;
}

export const requestFollow = (fromUser: UserType, toUser: UserType) => {
  return db.collection("relations").add({
    fromUserId: fromUser.uid,
    toUserId: toUser.uid,
    fromUser: getUserFields(fromUser),
    toUser: getUserFields(toUser),
    confirmed: false,
  });
};

export const getPluginsListener = (
  onNext: (snapshot: firebase.firestore.QuerySnapshot) => void,
  onError?: (error: Error) => void
) => {
  return db.collection("plugins").onSnapshot(onNext, onError);
};

export async function getPluginData(pluginId: string) {
  const pluginDataPromise = await db.collection("plugins").doc(pluginId).get();
  return pluginDataPromise.data() as PluginType;
}

export const storeNewPlugin = async (plugin: PluginType) => {
  const newPlugin = db.collection("plugins").add(plugin);
  return newPlugin;
};

export const updatePlugin = (id: string, plugin: PluginType) => {
  const updatedPlugin = db
    .collection("plugins")
    .doc(id)
    .update({
      ...omitBy(plugin, isNil),
    });
  return updatedPlugin;
};

export const getPluginTagsListener = (
  pluginId: string,
  onNext: (snapshot: firebase.firestore.QuerySnapshot) => void,
  onError?: (error: Error) => void
) => {
  return db
    .collection("plugin-tags")
    .where("pluginId", "==", pluginId)
    .onSnapshot(onNext, onError);
};

export const getTagsListener = (
  onNext: (snapshot: firebase.firestore.QuerySnapshot) => void,
  onError?: (error: Error) => void
) => {
  return db.collection("tags").onSnapshot(onNext, onError);
};

export async function getTags() {
  const tagsPromise = await db.collection("tags").get();
  const newTags: TagType[] = [];
  tagsPromise.forEach((doc) => {
    const tag: TagType = {
      uid: doc.id,
      name: doc.data().name,
    };
    newTags.push(tag);
  });

  return newTags;
}

export async function getPluginTags(pluginId: string) {
  const tagsPromise = await db
    .collection("plugin-tags")
    .where("pluginId", "==", pluginId)
    .get();
  const newPluginTags: PluginTagType[] = [];
  tagsPromise.docs.forEach((doc) => {
    const tag: PluginTagType = {
      uid: doc.id,
      pluginId: doc.data().pluginId,
      tagId: doc.data().tagId,
    };
    newPluginTags.push(tag);
  });

  return newPluginTags;
}

export const getWorkflowsListener = (
  onNext: (snapshot: firebase.firestore.QuerySnapshot) => void,
  onError?: (error: Error) => void
) => {
  return db.collection("workflows").onSnapshot(onNext, onError);
};

export const getWorkflowListener = (
  workflowId: string,
  onNext: (snapshot: firebase.firestore.DocumentSnapshot) => void,
  onError?: (error: Error) => void
) => {
  return db.collection("workflows").doc(workflowId).onSnapshot(onNext, onError);
};

export const getWorkflowJobsListener = (
  workflowId: string,
  onNext: (snapshot: firebase.firestore.QuerySnapshot) => void,
  onError?: (error: Error) => void
) => {
  return db
    .collection("jobs")
    .where("workflow_id", "==", workflowId)
    .orderBy("orderIndex")
    .onSnapshot(onNext, onError);
};

export const storeWorkflow = async (workflow: WorkflowType) => {
  const newWorkflow = db.collection("workflows").add({
    name: workflow.name,
  });
  newWorkflow.then((storedWorkflow) => {
    workflow.jobs.forEach((job) => {
      db.collection("jobs").add({
        workflow_id: storedWorkflow.id,
        ...omitBy(job, isNil),
      });
    });
  });
  console.debug(newWorkflow);
  return newWorkflow;
};

export const updateWorkflow = (id: string, workflow: WorkflowType) => {
  const updatedWorkflow = db
    .collection("workflows")
    .doc(id)
    .update({
      ...omitBy(workflow, isNil),
      jobs: firebase.firestore.FieldValue.delete(),
    });
  updateWorkflowJobs(workflow.jobs);
  return updatedWorkflow;
};

export async function getWorkflowJobData(jobId: string) {
  const jobDataPromise = await db.collection("jobs").doc(jobId).get();
  return jobDataPromise.data() as JobType;
}

export const updateWorkflowJobs = (jobs: JobType[]) => {
  let batch = db.batch();
  jobs.forEach((job) => {
    const ref = db.collection("jobs").doc(job.id);
    batch.update(ref, {
      ...omitBy(job, isNil),
    });
  });
  return batch.commit();
};

export const workflowAddJob = (workflow_id: string, job: JobType) => {
  return db.collection("jobs").add({
    workflow_id: workflow_id,
    ...omitBy(job, isNil),
  });
};

export const updateWorkflowJob = (id: string, job: JobType) => {
  return db
    .collection("jobs")
    .doc(id)
    .update({
      ...omitBy(job, isNil),
    });
};

export const deleteJob = (id: string) => {
  log("delete relation: %s", id);
  return db
    .collection("jobs")
    .doc(id)
    .delete()
    .then(() => {
      log("deleted: %s", id);
    })
    .catch((err) => {
      log("failed to delete: %s", err);
      throw err;
    });
};

export const confirmFollow = (id: string) => {
  return db.collection("relations").doc(id).update({ confirmed: true });
};

export const getUsersOnlineStatusListener = (
  onNext: (snapshot: firebase.firestore.QuerySnapshot) => void,
  onError?: (error: Error) => void
) => {
  return db
    .collection("users-online-status")
    .where("online", "==", true)
    .onSnapshot(onNext, onError);
};

export async function getUserOnlineStatus(userId: string) {
  const userDataPromise = await db
    .collection("users-online-status")
    .doc(userId)
    .get();
  return userDataPromise.data() as OnlineStatus;
}

export const getWorkflowCollaboratorsStatusListener = (
  workflowId: string,
  onNext: (snapshot: firebase.firestore.QuerySnapshot) => void,
  onError?: (error: Error) => void
) => {
  return db
    .collection("workflows-collaborator-status")
    .where("workflow_id", "==", workflowId)
    .onSnapshot(onNext, onError);
};

export const setWorkflowCollaboratorStatus = async (
  item: workflowsCollaboratorStatus
) => {
  const collection = db.collection("workflows-collaborator-status");
  const ref = collection
    .where("workflow_id", "==", item.workflow_id)
    .where("user_id", "==", item.user_id);
  ref.get().then((snapshot) => {
    if (snapshot.empty) {
      collection.add(item);
    } else {
      collection.doc(snapshot.docs[0].id).set(item);
    }
  });
};

export const storeUser = async (user: UserType) => {
  const newUser = db
    .collection("users")
    .doc(user.uid)
    .set({ name: user.name, email: user.email });
  console.debug(newUser);
  return newUser;
};

export const updateUserPresence = () => {
  const currentUser = firebase.auth().currentUser;
  console.debug("currentuser", currentUser);
  if (currentUser === null) return;

  const uid = currentUser.uid;
  var userStatusDatabaseRef = firebase
    .database()
    .ref("/users-online-status/" + uid);
  console.debug("updating user status for " + uid);

  var isOfflineForDatabase = {
    online: false,
    last_changed: firebase.database.ServerValue.TIMESTAMP,
  };

  var isOnlineForDatabase = {
    online: true,
    last_changed: firebase.database.ServerValue.TIMESTAMP,
  };

  var userStatusFirestoreRef = firebase
    .firestore()
    .doc("/users-online-status/" + uid);

  // Firestore uses a different server timestamp value, so we'll
  // create two more constants for Firestore state.
  var isOfflineForFirestore = {
    online: false,
    last_changed: firebase.firestore.FieldValue.serverTimestamp(),
  };

  var isOnlineForFirestore = {
    online: true,
    last_changed: firebase.firestore.FieldValue.serverTimestamp(),
  };

  firebase
    .database()
    .ref(".info/connected")
    .on("value", function (snapshot) {
      if (snapshot.val() === false) {
        // Instead of simply returning, we'll also set Firestore's state
        // to 'offline'. This ensures that our Firestore cache is aware
        // of the switch to 'offline.'
        console.debug("user went offline");
        userStatusFirestoreRef.set(isOfflineForFirestore);
        return;
      }

      userStatusDatabaseRef
        .onDisconnect()
        .set(isOfflineForDatabase)
        .then(function () {
          userStatusDatabaseRef.set(isOnlineForDatabase);
          console.debug("user went online");

          // We'll also add Firestore set here for when we come online.
          userStatusFirestoreRef.set(isOnlineForFirestore);
        });
    });
};

firebase.auth().onAuthStateChanged(function (user) {
  console.debug("user state changed", user);
  if (user) updateUserPresence();
});

export async function getChatData(chatId: string) {
  const chatDataPromise = await db.collection("chats").doc(chatId).get();
  return chatDataPromise.data() as Chat;
}

export const getWorkflowChatsListener = (
  workflowId: string,
  onNext: (snapshot: firebase.firestore.QuerySnapshot) => void,
  onError?: (error: Error) => void
) => {
  return db
    .collection("chats")
    .where("workflow_id", "==", workflowId)
    .onSnapshot(onNext, onError);
};

export const getChatMessagesListener = (
  chatId: string,
  onNext: (snapshot: firebase.firestore.QuerySnapshot) => void,
  onError?: (error: Error) => void
) => {
  return db
    .collection("chats-messages")
    .where("chat_id", "==", chatId)
    .orderBy("timestamp")
    .onSnapshot(onNext, onError);
};

export const chatAdd = async (workflowId: string, jobId: string) => {
  const newChat = await db.collection("chats").add({
    created: firebase.firestore.FieldValue.serverTimestamp(),
    workflow_id: workflowId,
    job_id: jobId,
    messages: 0,
  });
  return newChat.id;
};

export const chatAddMessage = (
  chatId: string,
  userId: string,
  message: string
) => {
  const increment = firebase.firestore.FieldValue.increment(1);
  const newMessage = db.collection("chats-messages").add({
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    chat_id: chatId,
    user_id: userId,
    message: message,
  });
  db.collection("chats").doc(chatId).update({ messages: increment });
  return newMessage;
};

export const getWorkflowGitLogListener = (
  workflowId: string,
  onNext: (snapshot: firebase.firestore.QuerySnapshot) => void,
  onError?: (error: Error) => void
) => {
  return db
    .collection("workflow-git-log")
    .where("workflow_id", "==", workflowId)
    .onSnapshot(onNext, onError);
};

/*
export interface RecipeOptions {
  title: string;
  plain: string;
  userId: string;
  description: string;
  image?: string;
  createdBy?: {
    email: string;
    photoURL: string;
  };
  author: string;
  ingredients: Ingredient[];
}

export const createEntry = (options: RecipeOptions) => {
  log("save recipe: %o", options);
  return db.collection("recipes").add({
    ...omitBy(options, isNil),
    updatedAt: firebase.firestore.Timestamp.fromDate(new Date())
  });
};

interface RecipeUpdateOptions {
  title: string;
  author: string;
  description: string;
  image?: string;
  createdBy?: {
    email: string;
    photoURL: string;
  };
  plain: string;
  ingredients: Ingredient[];
}

export const updateEntry = (id: string, options: RecipeUpdateOptions) => {
  return db
    .collection("recipes")
    .doc(id)
    .update({
      ...omitBy(options, isNil),
      image: options.image || firebase.firestore.FieldValue.delete()
    });
};

export const deleteEntry = (id: string) => {
  log("delete: %s", id);
  return db
    .collection("recipes")
    .doc(id)
    .delete();
};
*/
