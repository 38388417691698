// eslint-disable-next-line @typescript-eslint/no-unused-expressions
const firebaseConfig = {
  apiKey: "AIzaSyB_ckpNc2hiZRx7hooEGfsVbeOKkZc1M5c",
  authDomain: "gsmk-loppemarked.firebaseapp.com",
  databaseURL: "https://gsmk-loppemarked.firebaseio.com",
  projectId: "gsmk-loppemarked",
  storageBucket: "gsmk-loppemarked.appspot.com",
  messagingSenderId: "735597891103",
  appId: "1:735597891103:web:596e81b2310ddd985e0f2a",
  measurementId: "G-FT64QF8EJX",
};

/*
// Test server
const firebaseConfig = {
  apiKey: "AIzaSyDXDFaesNpVfa-kTUKUpmqbQFMru-5OnIs",
  authDomain: "gsmk-loppemarked-test.firebaseapp.com",
  databaseURL: "https://gsmk-loppemarked-test.firebaseio.com",
  projectId: "gsmk-loppemarked-test",
  storageBucket: "gsmk-loppemarked-test.appspot.com",
  messagingSenderId: "944474381193",
  appId: "1:944474381193:web:40a272e0beaf07b7345f58",
};
*/
export default firebaseConfig;
